import React from "react";
import "./App.css";
import Navbar from "./components/Navbar"; // Importing Material-UI components
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Charts from "./pages/Charts";
import AlertsPage from "./pages/AlertsPage";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/charts" element={<Charts />} />
        <Route path="/alerts" element={<AlertsPage />} />
        {/* RatesDisplay now holds the improved title and rate details */}
      </Routes>
    </Router>
  );
}

export default App;
