import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import krakenIcon from "../assets/icons/kraken_logo.png";
import valrIcon from "../assets/icons/valr_logo.png";
import fxIcon from "../assets/icons/fx_logo.png";
import lunoIcon from "../assets/icons/luno_logo.png";
import "../components/CardsStyle.css";

function Dashboard() {
  // State variables to hold the rates data and forex rate
  const [ratesData, setRatesData] = useState(null);
  const [forexRate, setForexRate] = useState(null);
  const [krakenRates, setKrakenRates] = useState({
    XBTUSD: null,
    ETHUSD: null,
    USDTUSD: null,
  }); // Add state for crypto/USD rates
  useEffect(() => {
    // Function to fetch cryptocurrency rates
    const fetchCryptoRates = async () => {
      try {
        // Sending a GET request to the backend route '/api/crypto-rates'
        const response = await fetch("/api/crypto-rates");
        const data = await response.json();
        // Updating the state with the fetched data
        // console.log(data)
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);

        data.currentResponseDate = formattedDate;
        setRatesData(data);
        setKrakenRates({
          XBTUSD: data.krakenCryptoRates.XXBTZUSD,
          ETHUSD: data.krakenCryptoRates.XETHZUSD,
          USDTUSD: data.krakenCryptoRates.USDTZUSD,
        });
      } catch (error) {
        console.error("Error fetching crypto rates:", error);
      }
    };

    function formatDate(date) {
      const options = {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date,
      );
      return formattedDate;
    }

    // Function to fetch the forex rate
    const fetchForexRate = async () => {
      try {
        // Sending a GET request to the backend route '/api/forex-rate'
        const response = await fetch("/api/forex-rate");
        const data = await response.json();
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);

        data.currentResponseDate = formattedDate;
        // Updating the state with the fetched forex rate
        console.log(data);
        setForexRate(data);
      } catch (error) {
        console.error("Error fetching forex rate:", error);
      }
    };

    // Fetching rates initially
    fetchCryptoRates();
    fetchForexRate();

    // Setting up intervals for fetching rates
    // Fetching crypto rates every 5 seconds and forex rate every 30 minutes
    const cryptoIntervalId = setInterval(fetchCryptoRates, 10000);
    const forexIntervalId = setInterval(fetchForexRate, 1800000);

    // Clearing the intervals when the component is unmounted
    return () => {
      clearInterval(cryptoIntervalId);
      clearInterval(forexIntervalId);
    };
  }, []); // Empty array as dependency means this useEffect runs once when component mounts, and cleanup runs when component unmounts

  // New useEffect to update document title
  useEffect(() => {
    if (ratesData) {
      document.title = `AR: ${Number(ratesData.arbitrageRate).toFixed(3)}%`;
    }
  }, [ratesData]); // Adding ratesData as a dependency so this useEffect runs whenever ratesData changes

  return (
    <div className="dashboard-wrap">
      {ratesData && forexRate ? (
        <div>
          <Card
            className="main-card card"
            variant="elevation"
            style={{
              margin: "0",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              borderRadius: "15px",
            }}
          >
            <CardContent>
              <div className="card-header">
                <Typography>Arbitrage Rate</Typography>
                <Typography>
                  {Number(ratesData.arbitrageRate).toFixed(3)}%
                </Typography>
              </div>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img
                        src={krakenIcon}
                        alt="Kraken Icon"
                        style={{ width: "22px" }}
                      />
                      {/*<KrakenIcon />*/}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Buy: USD/USDT"
                    secondary={ratesData.currentResponseDate}
                  />
                  <span className="rates-index">
                    ${Number(ratesData.krakenLastTradedPrice).toFixed(3)}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img
                        src={valrIcon}
                        alt="Valr Icon"
                        style={{ width: "22px" }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Sell: USDT/ZAR"
                    secondary={ratesData.currentResponseDate}
                  />
                  <span className="rates-index">
                    R{Number(ratesData.valrPairsData.USDTZAR).toFixed(3)}
                  </span>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img
                        src={fxIcon}
                        alt="FX Icon"
                        style={{ width: "18px", height: "12px" }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="FX: USDT/ZAR"
                    secondary={forexRate.currentResponseDate}
                  />
                  <span className="rates-index">
                    R{Number(forexRate.usdZarRate).toFixed(3)}
                  </span>
                </ListItem>
              </List>
            </CardContent>
          </Card>
          <div className="additional-card-block">
            <Card
              className="card-1 card"
              variant="elevation"
              style={{
                margin: "20px 20px 0 0",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                borderRadius: "15px",
              }}
            >
              <CardContent>
                <div className="card-header">
                  <Typography>Tickers: Crypto</Typography>
                </div>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={krakenIcon}
                          alt="Kraken Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="BTC/USD"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      ${Number(krakenRates.XBTUSD).toFixed(2)}
                    </span>
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={krakenIcon}
                          alt="Kraken Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="ETH/USD"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      ${Number(krakenRates.ETHUSD).toFixed(2)}
                    </span>
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={lunoIcon}
                          alt="Luno Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="BTC/ZAR"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.lunoRates.XBTZAR).toFixed(2)}
                    </span>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={valrIcon}
                          alt="Valr Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="BTC/ZAR"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.valrPairsData.BTCZAR).toFixed(2)}
                    </span>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={lunoIcon}
                          alt="Luno Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="ETH/ZAR"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.lunoRates.ETHZAR).toFixed(2)}
                    </span>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={valrIcon}
                          alt="Valr Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="ETH/ZAR"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.valrPairsData.ETHZAR).toFixed(2)}
                    </span>
                  </ListItem>
                </List>
              </CardContent>
            </Card>

            <Card
              className="card-2 card"
              variant="elevation"
              style={{
                margin: "20px 20px 0 0",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                borderRadius: "15px",
                height: "fit-content",
              }}
            >
              <CardContent>
                <div className="card-header">
                  <Typography>Tickers: Stables</Typography>
                </div>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={valrIcon}
                          alt="Valr Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="USDT/ZAR"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.valrPairsData.USDTZAR).toFixed(3)}
                    </span>
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={lunoIcon}
                          alt="Luno Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="USDT/ZAR"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.lunoRates.USDTZAR).toFixed(3)}
                    </span>
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={valrIcon}
                          alt="Valr Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="USDC/ZAR"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.valrPairsData.USDCZAR).toFixed(3)}
                    </span>
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={lunoIcon}
                          alt="Luno Icon"
                          style={{ width: "22px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="USDC/ZAR"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.lunoRates.USDCZAR).toFixed(3)}
                    </span>
                  </ListItem>

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={krakenIcon}
                          alt="Kraken Icon"
                          style={{ width: "22px" }}
                        />
                        {/*<KrakenIcon />*/}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Buy: USD/USDT"
                      secondary={ratesData.currentResponseDate}
                    />
                    <span className="rates-index">
                      ${Number(ratesData.krakenLastTradedPrice).toFixed(3)}
                    </span>
                  </ListItem>
                </List>
              </CardContent>
            </Card>

            <Card
              className="card-3 card"
              variant="elevation"
              style={{
                margin: "20px 20px 0 0",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                borderRadius: "15px",
                height: "fit-content",
              }}
            >
              <CardContent>
                <div className="card-header">
                  <Typography>Tickers: FX</Typography>
                </div>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={fxIcon}
                          alt="FX Icon"
                          style={{ width: "18px", height: "12px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="USD/ZAR"
                      secondary={forexRate.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(forexRate.usdZarRate).toFixed(3)}
                    </span>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <img
                          src={fxIcon}
                          alt="FX Icon"
                          style={{ width: "18px", height: "12px" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="EUR/ZAR"
                      secondary={forexRate.currentResponseDate}
                    />
                    <span className="rates-index">
                      R{Number(ratesData.eurZarRate).toFixed(3)}
                    </span>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </div>
        </div>
      ) : (
        <div className="loading">
          <CircularProgress color="primary" />
          <Typography
            variant="body2"
            style={{ marginTop: "1rem", color: "#9B9B9B" }}
          >
            Loading...
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
