import React from "react";
import * as MaterialIcon from "@mui/icons-material";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/",
    icon: <MaterialIcon.GridViewRounded />,
    cName: "nav-text",
  },
  {
    title: "Charts",
    path: "/charts",
    icon: <MaterialIcon.InsertChartOutlinedRounded />,
    cName: "nav-text",
  },
  {
    title: "Alerts",
    path: "/alerts",
    icon: <MaterialIcon.ProductionQuantityLimitsTwoTone />,
    cName: "nav-text",
  },
];
