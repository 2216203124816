import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import mainIcon from "../assets/icons/favicon1.png";
import "./Navbar.css";
import { Badge } from "@mui/material";

function Navbar(props) {
  let location = useLocation();
  const [alertCount] = useState("10");
  const [isMobileiOS, setIsMobileiOS] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobileiOS(/android|iphone|ipad|ipod/.test(userAgent));
  }, []);

  return (
    <>
      <nav className="nav-menu">
        <div className="nav-bar-header nav-item">
          {isMobileiOS ? (
            <>
              <img
                src={mainIcon}
                alt="mainIcon"
                className="nav-bar-header-icon"
              />
            </>
          ) : (
            <>
              <img
                src={mainIcon}
                alt="mainIcon"
                className="nav-bar-header-icon"
              />
              <h1>HighSeas</h1>
            </>
          )}
        </div>
        <ul className="nav-menu-items">
          {SidebarData.map((item, index) => {
            const isAlertsItem = item.title === "Alerts";
            const showAlertCount = isAlertsItem && !isMobileiOS;
            return (
              <li
                key={index}
                className={`${item.cName} nav-item ${
                  location.pathname === item.path ? "active" : ""
                }`}
              >
                <Link to={item.path}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {isMobileiOS ? (
                      <>
                        {isAlertsItem ? (
                          <Badge
                            badgeContent={10}
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "#ed4f9d",
                                color: "white",
                              },
                            }}
                          >
                            {item.icon}
                          </Badge>
                        ) : (
                          <>{item.icon}</>
                        )}
                      </>
                    ) : (
                      <>
                        {item.icon}
                        <span>{item.title}</span>
                      </>
                    )}
                  </div>
                  {showAlertCount && (
                    <div className="alert-count">
                      <div>{alertCount}</div>
                    </div>
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
